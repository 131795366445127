import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Image } from '../BackgroundImage/Image';
import { Exclaimation, FileUpload } from 'resources/Icons';
import Icon, { EditOutlined } from '@ant-design/icons';
import './uploadImageCard.scss';
import Tags from '../Tag';
import { TagText } from '../Cards';
import DocumentLoading from "../../../resources/images/loadingPdf.svg"

const { Text } = Typography;
type UploadImageCardProps = {
	imageUrl?: string;
	onEditDocument?: () => void;
	tagText?: string;
	imageName?: string;
	imageCode?: string;
	imageDate?: string;
	IconForButton: React.ReactElement;
	iconText: string;
	openModal?: () => void;
	isMobile?: boolean;
};
export const UploadImageCard: React.FC<UploadImageCardProps> = ({
	imageDate,
	imageCode,
	imageName,
	imageUrl,
	IconForButton,
	tagText,
	iconText,
	openModal = () => {},
	onEditDocument,
	isMobile,
}) => {
	const handleDownloadClick = () => {
		const link = document.createElement('a');
		link.href = imageUrl ?? '';
		link.download = 'document';
		link.target = '_blank';
		link.rel = 'noopener noreferrer';
		link.click();
	};

	const isPdf = imageUrl?.toLowerCase().includes('.pdf');

	const formatImageUrl = (imageUrl: string) => {
		const url = imageUrl
			.replaceAll(' ', '%20')
			.replaceAll('//', '/')
			.replace('https:/', 'https://');
		return url;
	};
	return (
		<>
			<Row justify="start" gutter={[8, 8]}>
				<Col style={{ paddingTop: '4px' }}>{IconForButton}</Col>
				<Col>
					<Text className="upload-grey-text">{iconText}</Text>
				</Col>
			</Row>
			{imageUrl !== undefined ? (
				<div className="container upload-image-card-container">
					<div className="image" onClick={handleDownloadClick}>
						{isMobile ? (
							<>
								{isPdf ? (
									<>
										<Image src={DocumentLoading}>
										{tagText !== TagText.Approved && (
											<Row justify="end" align="middle">
												<Col span={24} className="alignEnd">
													<span className="action">
														<Icon
															component={() => <EditOutlined />}
															onClick={onEditDocument}
														/>
													</span>
												</Col>
											</Row>
										)}
										<Row justify="start" className="bottomTag">
											<Tags tagText={tagText as TagText} />
										</Row>
										</Image>
									</>
								) : (
									<Image src={imageUrl ? formatImageUrl(imageUrl) : ' '}>
										{tagText !== TagText.Approved && (
											<Row justify="end" align="middle">
												<Col span={24} className="alignEnd">
													<span className="action">
														<Icon
															component={() => <EditOutlined />}
															onClick={onEditDocument}
														/>
													</span>
												</Col>
											</Row>
										)}
										<Row justify="start" className="bottomTag">
											<Tags tagText={tagText as TagText} />
										</Row>
									</Image>
								)}
							</>
						) : (
							<>
								{isPdf ? (
									<>
										<object
											data={imageUrl}
											type="application/pdf"
											className="background-image"
										></object>
										{tagText !== TagText.Approved && (
											<Row justify="end" align="middle">
												<Col span={24} className="alignEnd">
													<span className="action">
														<Icon
															component={() => <EditOutlined />}
															onClick={onEditDocument}
														/>
													</span>
												</Col>
											</Row>
										)}
										<Row justify="start" className="bottomTag">
											<Tags tagText={tagText as TagText} />
										</Row>
									</>
								) : (
									<Image src={imageUrl ? formatImageUrl(imageUrl) : ' '}>
										{tagText !== TagText.Approved && (
											<Row justify="end" align="middle">
												<Col span={24} className="alignEnd">
													<span className="action">
														<Icon
															component={() => <EditOutlined />}
															onClick={onEditDocument}
														/>
													</span>
												</Col>
											</Row>
										)}
										<Row justify="start" className="bottomTag">
											<Tags tagText={tagText as TagText} />
										</Row>
									</Image>
								)}
							</>
						)}
					</div>
					<div className="text__for__documents">
						<Row justify="center">
							<Col span={15}>
								<Row>
									<Col span={24}>
										<Text className="greenText">{imageName}</Text>
									</Col>
									<Col span={24}>
										<Text className="primary__text">{imageCode}</Text>
									</Col>
								</Row>
							</Col>
							<Col span={9}>
								<Row justify="end">
									<Col span={24}>
										<Row className="expiry-date-wrapper">
											<Col span={24}>
												<Text className="greenText">Expiry Date</Text>
											</Col>
											<Col span={24}>
												<Text className="primary__text">{imageDate}</Text>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
			) : (
				<div className="notUploadedImage" onClick={() => openModal()}>
					<Row justify="start" gutter={[16, 16]}>
						<Col>{IconForButton}</Col>
						<Col>
							<Text>{iconText}</Text>
						</Col>
						<Col>
							<Exclaimation color={'#EF571B'} />
						</Col>
					</Row>
					<Row justify="center" align="middle">
						<Col span={24} className="alignCenter">
							<FileUpload />
						</Col>
						<Col span={24} className="alignCenter">
							<Text className="greenText">Upload your file</Text>
						</Col>
						<Col span={24} className="alignCenter">
							<Text>(JPEG,JPG,PNG,PDF)</Text>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};
