import React, { useState, useEffect } from 'react';
import {
	Row,
	Col,
	Typography,
	Select,
	UploadFile,
	DatePicker,
	DatePickerProps,
	Space,
} from 'antd';

import {
	IdentityProof,
	StudentProof,
	UploadFile as UploadFileIcon,
} from 'resources/Icons';
import { UploadImageCard } from 'shared/components/UploadImageCard';
import ModalComponent from 'shared/components/Modal';

import { InputFieldForEmail } from 'shared/components/InputField';
import UploadImage from 'shared/components/UploadImage';
import DocumentsService from 'services/DocumentsService/documents.service';

import { OnboardingDocuments } from 'models/OnboardingDocuments/onboardingDocuments.model';
import moment from 'moment';
import DocumentLoading from "../../resources/images/documentLoading.png"
import { DateFormats } from 'enums/dateFormat';
import useResponsive from 'shared/Hooks/useResponsive';
import SelectInput from 'shared/components/Select/SelectInput';

const { Text } = Typography;
const { Option } = Select;

const Documents = (): JSX.Element => {
	const {isMobile} = useResponsive();
	const [fileList, setFileList] = useState<UploadFile[] | any>([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedDocument, setSelectedDocument] =
		useState<OnboardingDocuments>();

	const [documentsData, setDocumentsData] = useState({
		documentType: '',
		documentNumber: '',
		documentDate: '',
	});
	const {
		fetchDocuments,
		documents,
		documentNameList,
		buttonLoading,
		getDocumentNameList,
		uploadDocuments,
	} = DocumentsService();

	const showHideModal = (): any => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		fetchDocuments();
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		getDocumentNameList();
	}, []);

	const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
		if (!dateString) {
			return;
		}
		setDocumentsData({ ...documentsData, documentDate: dateString });
	};
	useEffect(() => {
		if (selectedDocument !== undefined) {
			const file = [{ thumbUrl: selectedDocument?.filePath }];
			setFileList(file);
			setDocumentsData({
				documentType: selectedDocument.docType ?? '',
				documentDate: selectedDocument.expiryDate ?? '',
				documentNumber: selectedDocument.idNumber ?? '',
			});
		}
	}, [selectedDocument]);

	const uploadDocument = async () => {
		if (selectedDocument && documentsData) {
			const params = {
				document: fileList[0].originFileObj,
				documentName: selectedDocument.docNameId,
				documentFor: selectedDocument.docFor,
				idNumber: documentsData.documentNumber,
				documentType: documentsData.documentType,
				expiryDate: documentsData.documentDate,
				isUploaded: true,
				documentId: selectedDocument.id,
			};

			await uploadDocuments(params);
		
		}
	};
	const TitleForModal = () => {
		return (
			<Space direction="horizontal">
				<UploadFileIcon />
				<Text>Upload your file</Text>
			</Space>
		);
	};
	return (
		<>
			<ModalComponent
				shouldModalOpen={showHideModal}
				visible={showModal}
				title={<TitleForModal />}
				okButtonText="Save"
				cancelButtonText="Cancel"
				onButtonOkClick={uploadDocument}
				showFooter={true}
				buttonLoading={buttonLoading}
			>
				<Row>
					<Col span={24}>
						<Text type="secondary" className="secondary__text">
							Document Type
						</Text>
					</Col>
					<Col span={24}>
						<SelectInput
							className="profile-select"
							value={selectedDocument?.docName ?? ''}
							onSelect={(e: any) =>
								setDocumentsData({ ...documentsData, documentType: e })
							}
						>
							{documentNameList?.map((i) => (
								<Option value={i.name} key={i.id}>
									{i.name}
								</Option>
							))}
						</SelectInput>
					</Col>
				</Row>
				<Row>
					{documentsData.documentType !== 'Others' &&
						documentsData.documentType !== 'Letter' && (
							<>
								<Col span={24}>
									<Text type="secondary" className="secondary__text">
										ID Number
									</Text>
								</Col>
								<Col span={24}>
									<InputFieldForEmail
										value={documentsData.documentNumber}
										type="email"
										placeholder={`enter your ${
											documentsData.documentType ?? 'verification'
										} number`}
										onChange={(e: any) =>
											setDocumentsData({
												...documentsData,
												documentNumber: e.target.value,
											})
										}
									/>
								</Col>
							</>
						)}
				</Row>

				<Row>
					<Col span={24}>
						<Text type="secondary">Valid Till</Text>
					</Col>
					<Col span={24}>
						<DatePicker
							picker="date"
							value={moment(documentsData.documentDate, 'YYYY-MM-DD')}
							style={{ width: '100%' }}
							placeholder="Select month"
							onChange={onChangeDate}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col span={24}>
						<UploadImage
							length={1}
							fileList={fileList}
							setFileList={setFileList}
							accept={'.pdf,.jpeg,.jpg,.png'}
						/>
					</Col>
				</Row>
				<br />
			</ModalComponent>
			<Row gutter={[16, 32]} >
				<Col span={24} md={20} lg={11} xl={7} xxl={6} className='mr-6'>
					{documents !== undefined ? (
						<UploadImageCard
							tagText={documents[0]?.statusId?.name ?? 'Processing...'}
							imageUrl={documents[0]?.filePath ?? DocumentLoading}
							imageCode={documents[0]?.idNumber ?? '090909090'}
							imageDate={
								moment(documents[0]?.expiryDate).format(DateFormats.SECONDARY) ??
								'11 Feb 2023'
							}
							imageName={documents[0]?.docName ?? 'jackson'}
							iconText="Identity Proof"
							IconForButton={<IdentityProof color={'#65BCB0'}  />}
							onEditDocument={() => {
								if (documents) {
									setSelectedDocument(documents[0]);
								}
								showHideModal();
							}}
							isMobile={isMobile}
						/>
					) : (
						<UploadImageCard
							iconText="Identity proof"
							IconForButton={<StudentProof />}
							openModal={() => showHideModal()}
						/>
					)}
				</Col>
				<Col span={24} md={20} lg={11} xl={7} xxl={6}>
					{documents === undefined ? (
						<UploadImageCard
							iconText="Student Proof"
							IconForButton={<StudentProof />}
							openModal={() => {
								if (documents) {
									setSelectedDocument(documents[1]);
								}
								showHideModal();
							}}
						/>
					) : (
						<UploadImageCard
							tagText={documents[1]?.statusId?.name ?? 'Processing...'}
							imageUrl={documents[1]?.filePath ?? DocumentLoading}
							imageCode={documents[1]?.idNumber ?? '090909090'}
							imageDate={
								moment(documents[1]?.expiryDate).format(DateFormats.SECONDARY) ??
								'21/2/22'
							}
							imageName={documents[1]?.docName ?? 'jackson'}
							iconText={documents[1]?.docType ?? 'Identity Proof'}
							IconForButton={<StudentProof color="#65BCB0" />}
							onEditDocument={() => {
								if (documents) {
									setSelectedDocument(documents[1]);
								}
								showHideModal();
							}}
							isMobile={isMobile}
						/>
					)}
				</Col>
			</Row>
		</>
	);
};

// function areEqual(prevProps: any, nextProps: any) {
// 	return prevProps === nextProps;
// 	/*
// 	return true if passing nextProps to render would return
// 	the same result as passing prevProps to render,
// 	otherwise return false
// 	*/
// }
export default React.memo(Documents);
