import React, { FC, ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import isAuthenticated from 'shared/components/HOC/requireAuth';
import AppWrapper from 'shared/components/Layout';
import AuthWrapper from 'views/Auth/AuthWrapper';
import { AppRoutes } from './routeConstants/appRoutes';

type routerProps = {
	exact: boolean;
	path: string;
	component: FC | ReactElement;
};
const AppRouterWrapper = () => {
	const routes: routerProps[] = [
		{ path: AppRoutes.AUTH, component: <AuthWrapper />, exact: true },
		{
			path: AppRoutes.APP,
			component: isAuthenticated(<AppWrapper />),
			exact: false,
		},
	];

	return (
		<BrowserRouter>
			<Routes>
				{routes.map((route, index) => (
					<Route key={index} {...route} element={route.component as FC} />
				))}
				<Route path="*" element={<AuthWrapper />} />
			</Routes>
		</BrowserRouter>
	);
};
export default AppRouterWrapper;
