import { Row, Col, Space, Typography, Select, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { QuestionMarkIcon } from 'resources/Icons';
import MaintenanceService from 'services/MaintenanceService/Maintenance.service';
import ModalComponent from 'shared/components/Modal';
import SelectInput from 'shared/components/Select/SelectInput';
import UploadImage from 'shared/components/UploadImage';
export default function MaintenanceModal({
	showModal,
	showHideModal,
	isMobile,
}: {
	showModal: boolean;
	showHideModal: () => void;
	isMobile?: boolean;
	
}) {
	const {
		raiseATicket,
		buttonLoading,
		ticketLocations,
		ticketCategories,
		getTicketCategories,
		getTicketLocations,
	} = MaintenanceService();
	const [ticketCategory, setTicketCategory] = useState<number>();
	const [description, setDescription] = useState<string>();
	const [locationId, setLocationId] = useState<number>();
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const { Text } = Typography;
	const { Option } = Select;

	const changeLocationId = (item: string) => {
		if (ticketLocations !== undefined) {
			const { id } = ticketLocations?.filter((i) => i.name === item)[0];
			setLocationId(id);
		}
	};

	const changeTicketCategory = (item: string) => {
		if (ticketCategories !== undefined) {
			const { id } = ticketCategories?.filter((i) => i.name === item)[0];
			setTicketCategory(id);
		}
	};

	useEffect(() => {
		if (!ticketCategories) {
			void getTicketCategories();
		}
		if (!ticketLocations) {
			void getTicketLocations();
		}
	}, []);

	useEffect(() => {
		if (ticketLocations !== undefined) {
			const { id } = ticketLocations[0];
			setLocationId(id);
		}
		if (ticketCategories !== undefined) {
			const { id } = ticketCategories[0];
			setTicketCategory(id);
		}
	}, [ticketCategories, ticketLocations]);

	const createTicket = async () => {
		const params = {
			ticket_category: ticketCategory,
			description,
			location_id: locationId,
			attachments: fileList,
		};
		await raiseATicket(params).then((res) => {
			if (res?.status === 200) {
				showHideModal();
			}
		});
	};
	return (
		<ModalComponent
			shouldModalOpen={showHideModal}
			className={isMobile ? "maintenance-ticket-modal": "raise-ticket-modal"}
			visible={showModal}
			title={
				<Space direction="horizontal">
					<QuestionMarkIcon />
					<Text>Raise A Ticket</Text>
				</Space>
			}
			okButtonText="Send"
			cancelButtonText="Cancel"
			onButtonOkClick={createTicket}
			buttonLoading={buttonLoading}
		>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Row gutter={[8, 0]}>
						<Col span={24}>
							<Text type="secondary" className="secondary__text">
								Location
							</Text>
						</Col>
						<Col span={24}>
							{ticketLocations !== undefined && ticketLocations.length > 0 ? (
								<SelectInput
									className="profile-select"
									style={{ width: '100%' }}
									listHeight={150}
									defaultValue={ticketLocations[0].name}
									onChange={(item) => changeLocationId(item)}
								>
									{ticketLocations?.map((i) => (
										<Option value={i.name} key={i.id}>
											{i.name}
										</Option>
									))}
								</SelectInput>
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Col>

				<Col span={24}>
					<Row gutter={[8, 0]}>
						<Col span={24}>
							<Text type="secondary" className="secondary__text">
								Category
							</Text>
						</Col>
						<Col span={24}>
							{ticketCategories !== undefined && ticketCategories.length > 0 ? (
								<SelectInput
									className="profile-select"
									style={{ width: '100%' }}
									listHeight={200}
									defaultValue={ticketCategories[0].name}
									onChange={(i) => changeTicketCategory(i)}
								>
									{ticketCategories?.map((i) => (
										<Option value={i.name} key={i.id}>
											{i.name}
										</Option>
									))}
								</SelectInput>
							) : (
								<></>
							)}
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<Row gutter={[8, 0]}>
						<Col span={24}>
							<Text type="secondary" className="secondary__text">
								Description
							</Text>
						</Col>
						<Col span={24}>
							<TextArea
								placeholder="Description"
								autoSize={{ minRows: 2, maxRows: 6 }}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<br />
			<Row>
				<UploadImage setFileList={setFileList} fileList={fileList} />
			</Row>
			<br />
		</ModalComponent>
	);
}
